import {useCallback, useEffect, useMemo, useState} from "react";
import * as api from "../../api/api-client";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {FieldValues, useForm} from "react-hook-form";
import {Box, Button, Card, Container, FormControl, InputLabel, MenuItem, Select, Toolbar} from "@mui/material";
import BasicFormControl from "../../components/form-helpers/BasicFormControl";
import DatePickerFormControl from "../../components/form-helpers/DatePickerFormControl";
import { IRepairEstimate } from './RepairEstimatePage';
import { AutofleetOption, CategoryOption, PartnerOption } from '../../components/forms/CreateRepairEstimateForm';
import SelectFormControl from '../../components/form-helpers/SelectFormControl';
import { currencyOptions, otherExpensesOption, serviceLists, defaultValues } from './constants';

const RepairEstimateDetailsPage = () => {
  const { id } = useParams();
  console.log(id)
  const [editMode, setEditMode] = useState<boolean>(false);
  const [optionSelected, setOptionSelected] = useState<string>('');
	const [categoryOptions, setCategoryOptions] = useState<CategoryOption[]>();
  const { handleSubmit, reset, control } = useForm({ defaultValues });
  const categorySelected: CategoryOption | undefined = useMemo(
		() => categoryOptions?.find((el) => el.reg_no === optionSelected || el.name === optionSelected),
		[categoryOptions, optionSelected]
	);

  const toggleEdit = () => setEditMode(prev => !prev);

  const handleRepairEstimateUpdate = useCallback(
    async (data: FieldValues) => {
      try {
        const { deviz_id, ...body} = data;
        const { auto_fleet_id, partner_id } = categorySelected || {};
				const res = await api.put(`/deviz/${deviz_id}`, {
					...body,
					...{ auto_fleet_id, partner_id }
        });
        toggleEdit();
        toast.success(res?.message);
      } catch (err) {
        const { error } = err as { error: string };
        toast.error(error);
			}
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps    
		[categorySelected]
	);

  useEffect(() => {
    (async function getOptions() {
      try {
        const autofleet = (await api.get('/autofleet/options')) as AutofleetOption[];
        const partners = (await api.get('/partners')) as PartnerOption[];
        const tempOptions: CategoryOption[] = [
          ...autofleet,
          ...partners.map(({ partner_id, name }) => ({ partner_id, name })),
        ];
        setCategoryOptions(tempOptions);
      } catch (err) {
        const { error } = err as { error: string };
        toast.error(error);
			}
    })();
  }, [])

  useEffect(() => {
    (async function getDeviz() {
      if(categoryOptions?.length)
        try {
          const { auto_fleet_id, partner_id, denumire, date, pret, currency, infos, deviz_id } = (await api.get(`/deviz/${id}`)).message as IRepairEstimate;
          const categorySelected = categoryOptions?.find((el) => el.auto_fleet_id?.toString() === auto_fleet_id || el.partner_id === partner_id);
          !!categorySelected && setOptionSelected(categorySelected?.reg_no ?? (categorySelected?.name || ''));
          reset({ auto_fleet_id, partner_id, denumire, date, pret, currency, infos, deviz_id });
        } catch (err) {
          const { error } = err as { error: string };
          toast.error(error);
        }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, categoryOptions]);

  return (
    <Container sx={{height: '100%', margin: 0, marginTop: '3rem', maxWidth: '100vw !important'}}>
      <Toolbar sx={{width: '100%', paddingLeft: '0px !important', paddingRight: '0px !important'}}>
        <Card sx={{width: '100%'}}>
          <Button onClick={toggleEdit}>Toggle edit</Button>
        </Card>
      </Toolbar>
      <form onSubmit={handleSubmit(handleRepairEstimateUpdate)}>
      <Box sx={{ display: 'flex', flexDirection: 'column', ml: '-1rem' }}>
				{/* Category Selection */}
        <FormControl disabled={!editMode} sx={{width: '100%', mx: {xs: '0', md: '1rem'}}} variant={'standard'}>
					<InputLabel id='select-label'>Categorie</InputLabel>
					<Select
						value={optionSelected}
						onChange={(e) => setOptionSelected(e.target.value)}>
						{categoryOptions?.map((el, i) => (
							<MenuItem value={el.reg_no ?? el.name} key={`category-item-${i}`}>{el.reg_no ?? el.name}</MenuItem>
						))}
					</Select>
				</FormControl>
				<DatePickerFormControl
					disabled={!editMode || !categorySelected}
					control={control}
					name='date'
					displayText='Data'
					variant='standard'
				/>
				<SelectFormControl
					disabled={!editMode || !categorySelected}
          name='denumire'
          displayText='Denumire'
					control={control}
          selectOptions={
						!optionSelected ? [] : serviceLists[categorySelected?.auto_fleet_id ? 'autofleet' : 'partner'].concat(otherExpensesOption)
					}
          variant='standard'
				/>
				<BasicFormControl
					disabled={!editMode || !categorySelected}
					type='number'
					control={control}
					name='pret'
          displayText='Pret'
				/>
        <SelectFormControl
					disabled={!editMode || !categorySelected}
					name={'currency'}
					control={control}
					displayText='Moneda'
					selectOptions={currencyOptions}
					variant='standard'
				/>
				<BasicFormControl
					disabled={!editMode || !categorySelected}
					control={control}
					name='infos'
					displayText='Observatii'
				/>
        {editMode &&
            <Button disabled={!editMode} type='submit' variant='contained' sx={{width: '100%', my: 2}}>Submit</Button>
        }
			</Box>

      </form>
    </Container>
  )
}

export default RepairEstimateDetailsPage;