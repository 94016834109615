import {Box, Typography} from "@mui/material";
import ReportsCard from "../reports-card/ReportsCard";
import { MonetizationOn, ReceiptLong} from "@mui/icons-material";
import {useEffect, useState} from "react";

interface Props {
  content: any
}

interface Total {
  currency: string;
  amount: number;
}

const RepairEstimateReports = (props: Props) => {
  const [total, setTotal] = useState<Total[]>([]);
  const [totalRepairEstimates, setTotalRepairEstimates] = useState<number>(0);

  useEffect(() => {
    setTotalRepairEstimates(props?.content?.length);
  }, [props.content]);

  useEffect(() => {
    let ronProfit: Total = {currency: 'RON', amount: 0};
    let eurProfit: Total = {currency: 'EUR', amount: 0};

    props?.content?.map((repairEstimate: any) => {
      return repairEstimate.currency === 'RON' ? ronProfit.amount += Number(repairEstimate.pret) : repairEstimate.currency === 'EURO' ? eurProfit.amount += Number(repairEstimate.pret) : 0;
    });

    eurProfit.amount = parseFloat(eurProfit.amount?.toFixed(2));
    ronProfit.amount = parseFloat(ronProfit.amount?.toFixed(2));

    setTotal([eurProfit, ronProfit]);
  }, [props.content])

  return <Box sx={{
    display: 'flex',
    flexWrap: 'wrap', justifyContent: {xs:'space-evenly', md: 'space-between'}}}>
    <ReportsCard titleText='Total' icon={<MonetizationOn/>} content={<>
      {total.map((profitItem: Total) => (
        <Box sx={{display: 'flex'}}>
          <Typography color='green' fontWeight='bold'
                      sx={{marginRight: '0.5rem'}}>{profitItem.amount}</Typography>
          <Typography color='white' fontWeight='bold'
                      sx={{textShadow: '1px 1px 2px black'}}>{profitItem.currency}</Typography>
        </Box>
      ))}
    </>}/>
    <ReportsCard titleText='Numar devize' icon={<ReceiptLong/>} content={<Box sx={{display: 'flex'}}>
      <Typography color='green' fontWeight='bold'
                  sx={{
                    marginRight: '0.5rem',
                  }}>{totalRepairEstimates}</Typography>
      <Typography color='white' fontWeight='bold'
                  sx={{
                    marginRight: '0.5rem',
                    textShadow: '1px 1px 2px black',
                    fontSize: '1rem'
                  }}>
        {totalRepairEstimates === 1 ? 'deviz' : 'devize'}
      </Typography>
    </Box>}/>
  </Box>
}

export default RepairEstimateReports;