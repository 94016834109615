export const NoticesTableHeader = [
  {
    id: ['series', 'number'],
    label: 'Numar',
    width: 100,
    type: 'href',
    composite: true
  },
  {
    id: "buyer",
    label: "Beneficiar",
    width: 100,
  },
  {
    id: "driver_name",
    label: "Sofer",
    width: 100   
  },
  {
    id: "car_reg_number",
    label: "Masina",
    width: 100,
  },
  {
    id: "created_at_utc",
    label: "Data emitere",
    width: 100,
    type: 'date'
  },
  {
    id: "pickup_address",
    width: 100,
    label: "Locatie incarcare"
  },
  {
    id: "drop_off_address",
    width: 100,
    label: "Locatie descarcare"
  },
  {
    id: "product_name",
    width: 100,
    label: "Produs"
  },
  {
    id: "quantity",
    width: 100,
    label: "Cantitate"
  },
  {
    id: "unit_of_measure",
    width: 100,
    label: "U.M."
  },
  {
    id: "notice_status",
    width: 10,
    label: 'Stare aviz'
  },
  {
    id: "uit",
    width: 100,
    type: "anaf",
    label: "e-Transport"
  }
]