import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {Close} from "@mui/icons-material";
import {useEffect, useState} from "react";

interface Props {
  rowDeleteRequested: any;
  deleteConfirmationText: string;
  handleDeleteRow: () => void;
  rowDeleteCanceled: (val: any) => void;
}

const DeleteConfirmationDialog = (props: Props) => {
  const [rowDeleteRequested, setRowDeleteRequested] = useState(props.rowDeleteRequested);
  const [deleteRowConfirmed, setDeleteRowConfirmed] = useState<boolean>(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleDeleteConfirmationInputChange = (event: any) => {
    const {value} = event.target;

    if (value?.toString()?.toUpperCase() === props?.deleteConfirmationText?.toString().toUpperCase()) {
      setDeleteRowConfirmed(true);
    } else {
      setDeleteRowConfirmed(false);
    }
  }

  useEffect(() => {
    setRowDeleteRequested(props.rowDeleteRequested);
  }, [props.rowDeleteRequested])

  return (
    <Dialog
      fullScreen={fullScreen}
      open={!!rowDeleteRequested}
      aria-labelledby="responsive-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="responsive-dialog-title"
                   sx={{display: 'flex', justifyContent: 'space-between'}}>
        Stergeti randul
        <IconButton
          edge="end"
          color="inherit"
          onClick={() => props.rowDeleteCanceled(null)}
          aria-label="close"
          sx={{justifySelf: 'end'}}
        >
          <Close/>
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{height: '100%'}}>
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
          <Typography>Sigur doriti sa stergeti acest rand? Operatiunea nu poate fi anulata! </Typography>
          <Typography>Text de confirmare: <b>{props.deleteConfirmationText}</b></Typography>
          <TextField onChange={handleDeleteConfirmationInputChange}
                     helperText="Introduceti textul de confirmare pentru a sterge randul selectat."></TextField>
          <Button variant='contained' onClick={props.handleDeleteRow} disabled={!deleteRowConfirmed}>Confirm</Button>
        </Box>
      </DialogContent>
    </Dialog>
  )

}

export default DeleteConfirmationDialog;